import React from 'react';

import { Hat } from 'components/Hat/Hat';
import { Seo, Feed, Hello, SeoHidden } from 'components';
import { GetCategory } from 'query';

export default ({ pageContext: { id } }) => (
  <GetCategory {...{ id }}>
    {({ title, feed, description, seo_title, seo_h1 }) => (
      <>
        <Seo title={seo_title || title} description={description} />
        {seo_h1 ? (
          <>
            <Hat title={title} pageType={id} />
            <SeoHidden>
              <h1>{seo_h1}</h1>
            </SeoHidden>
          </>
        ) : (
          <Hat title={title} as={'h1'} pageType={id} />
        )}
        <Feed
          list={feed.map((item) => {
            if (/\//.test(item)) {
              return item;
            } else {
              return [id, item].join('/');
            }
          })}
        />
      </>
    )}
  </GetCategory>
);
